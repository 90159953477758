import React from 'react';
import { node } from 'prop-types';
import Container from '@bit/medicalwebexperts.mwe-ui.container';

const propTypes = {
  children: node.isRequired,
};

const Layout = ({ children }) => <Container my={8}>{children}</Container>;

Layout.propTypes = propTypes;

export default Layout;
