import React from 'react';
import ThemeProvider from '@bit/medicalwebexperts.mwe-ui.theme-provider';
import Normalize from '@bit/medicalwebexperts.mwe-ui.normalize';
import theme from './theme/preset';

// eslint-disable-next-line react/prop-types
const WrapRoot = ({ element }) => (
  <ThemeProvider theme={theme}>
    <Normalize />
    {element}
  </ThemeProvider>
);

export default WrapRoot;
