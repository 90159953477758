import preset from '@bit/medicalwebexperts.mwe-ui.themes.preset';

export default {
  ...preset,
  fonts: {
    ...preset.fonts,
    body:
      'Roboto, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", sans-serif',
    heading:
      'Roboto, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", sans-serif',
  },
  colors: {
    ...preset.colors,
    background: '#f9f9f9',
    primary: {
      light: '#2888DD',
      main: '#012758',
    },
    secondary: {
      main: '#A87F50',
    },
    info: '#012758',
  },
  typography: {
    h1: {
      fontFamily: 'heading',
      fontSize: '2xl',
      fontWeight: 'normal',
      lineHeight: 'shorter',
      letterSpacing: 'tight',
      color: 'primary.main',
    },
    h2: {
      fontFamily: 'body',
      fontSize: 'xl',
      fontWeight: 'normal',
      lineHeight: 'base',
      mb: 4,
      color: 'primary.main',
    },
    h3: {
      fontFamily: 'body',
      fontSize: 'md',
      fontWeight: 'medium',
      lineHeight: 'base',
      mb: 4,
      color: 'black',
    },
    body1: {
      fontFamily: 'body',
      fontSize: 'md',
      fontWeight: 'normal',
      lineHeight: 'tall',
    },
    body2: {
      fontFamily: 'body',
      fontSize: 'sm',
      fontWeight: 'medium',
      lineHeight: 'tall',
    },
    caption: {
      fontFamily: 'body',
      fontSize: 'xs',
      fontWeight: 'normal',
      lineHeight: 'base',
    },
    inherit: {},
  },
};
